import { useAppState } from '../../contexts/AppStateProvider';

const Step5 = () => {
  const appState = useAppState();
  const { successfulTransfer } = appState.data;

  return (
    <>
      <div className="card w-2/4 bg-base-100 shadow-xl mb-16">
        <div className="card-body flex justify-center">
          {(successfulTransfer) ? (
            <div
              className="bg-green-100 border-l-4 border-green-500
                text-green-700 p-4"
              role="alert"
            >
              <div className="flex">
                <div className="place-self-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <div className="px-4 font-medium">
                  Transfer successfully executed.
                </div>
              </div>
            </div>
          ) : (
            <div
              className="bg-red-100 border-l-4 border-red-500
                text-red-700 p-4"
              role="alert"
            >
              <div className="flex">
                <div className="place-self-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0
                      2.502-1.667 1.732-3L13.732
                      4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77
                      1.333.192 3 1.732 3z"
                    />
                  </svg>
                </div>
                <div className="px-4 font-medium">
                  Transfer blocked. Remote desktop access detected.
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Step5;
