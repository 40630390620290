import { useEffect, useState } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import {
  STAGE_CALIBRATION,
  STAGE_TESTING,
  useAppState,
} from '../../contexts/AppStateProvider';

const STEPS = [{
  content: <div className="text-left space-y-4">
    <p>Hi there!</p>
    <p>
      Graboxy blocks tech support scams by detecting remote desktop access.
    </p>
    <p>
      Test it and let us know how you like it.
    </p>
  </div>,
  disableBeacon: false,
  placement: 'center',
  beaconPlacement: 'left-start',
  target: 'body',
}, {
  content: <div className="text-left space-y-4">
    <p>
      First we’ll calibrate the system with you, as the new user, while you
      complete a mock bank transfer.
    </p>
    <p>
      Make sure NOT to engage a remote access tool during the calibration.
    </p>
  </div>,
  placement: 'auto',
  target: '#menu-calibration',
  spotlightPadding: 5,
}, {
  content: <div className="text-left space-y-4">
    <p>
      After the calibration, you may complete more mock bank transfers.
    </p>
    <p>
      Complete some transfers yourself, and also let someone else try doing the
      same by remotely controlling your device.
    </p>
    <p>
      See if Graboxy can block the transfers attempted through remote access.
    </p>
  </div>,
  placement: 'auto',
  target: '#menu-testing',
  spotlightPadding: 5,
}, {
  content: <div className="text-left space-y-4">
    <div className="text-center text-lg font-medium">
      Warning<span className="text-red-500 ml-1 font-normal">&#x26A0;</span>
    </div>
    <div className="font-medium">For best results:</div>
    <ul className="list-disc ml-4">
      <li>Use the cursor, not just the TAB key.</li>
      <li>Stick to the same pointing device for all sessions.</li>
      <li>
        Avoid niche browsers, touchscreens, tablets, phones, or gaming consoles.
      </li>
    </ul>
  </div>,
  placement: 'auto',
  target: '#minimum-system-requirements',
  spotlightPadding: 0,
  spotlightClicks: true,
}, {
  content: <div className="text-left space-y-4">
    <p>Let&apos;s get started.</p>
    <p>Show us the real you.</p>
  </div>,
  hideFooter: true,
  placement: 'left',
  beaconPlacement: 'left',
  spotlightClicks: true,
  target: '#start-calibration',
  spotlightPadding: 0,
  styles: {
    beacon: {
      left: '-20px',
    },
  },
}, {
  content: <div className="text-left space-y-4">
    <p>We&apos;re tracking your interactions now.</p>
    <p>Keep moving until the progress bar turns blue.</p>
  </div>,
  placement: 'top-end',
  target: '#gsr-progress',
  hideBackButton: true,
}, {
  content: <div className="text-left space-y-4">
    <p>Now that we know you, feel free to test as much as you like.</p>
    <p>Are you making the transfer, or is someone else doing it remotely?</p>
    <p>Our sensors will spot the difference.</p>
  </div>,
  target: '#start-testing',
  placement: 'left',
  beaconPlacement: 'left',
  hideFooter: true,
  spotlightClicks: true,
  spotlightPadding: 0,
  styles: {
    beacon: {
      left: '-20px',
    },
  },
}];

const STEP0_BEACON = {
  placement: 'left-start',
  target: '#mockup-browser',
};

const STEP0_TOOLTIP = {
  placement: 'center',
  target: 'body',
};

export default function JoyRide() {
  const [state, setState] = useState({
    continuous: true,
    run: false,
    steps: STEPS,
    stepIndex: 0,
  });

  const {
    continuous,
    run,
    stepIndex,
    steps,
  } = state;
  const { stage, step } = useAppState();

  const updateWithDelay = (newState) => {
    setState({
      ...state,
      run: false,
    });
    setTimeout(
      () => setState({
        ...state,
        run: true,
        ...newState,
      }),
      100,
    );
  };

  useEffect(() => {
    switch (stage) {
      case STAGE_CALIBRATION:
        // The first page sets the state running from the start
        if (step === 0) {
          setState({
            ...state,
            stepIndex: 0,
            run: true,
          });
        }
        // On the second page we show the 6th tooltip
        if (step === 1 && run === true) {
          updateWithDelay({
            stepIndex: 5,
          });
        }
        break;
      case STAGE_TESTING:
        // On the first page of verification we start with the 7th tooltip
        if (step === 0) {
          setState({
            ...state,
            run: true,
            stepIndex: 6,
          });
        }
        // On the second page we finish displaying the tooltips by setting the
        // index to a non existing one.
        if (step === 1 && run === true) {
          updateWithDelay({
            stepIndex: 7,
          });
        }
        break;
      default:
        break;
    }
  }, [stage, step]);

  const handleJoyrideCallback = (data) => {
    const {
      action,
      index,
      status,
      type,
    } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      // When the last tooltip was displayed we switch to beacon mode (by
      // setting continuous to false), so by default no tooltip will be shown if
      // the data collection is restarted.
      // We also set the the first step to support beacons.
      const newSteps = [
        {
          ...steps[0],
          ...STEP0_BEACON,
        },
        ...steps.splice(1),
      ];

      setState({
        ...state,
        steps: newSteps,
        continuous: false,
        run: false,
      });
    } else if (type === EVENTS.TOOLTIP) {
      if (continuous === false) {
        // If a beacon is clicked we switch to tooltip mode (by setting
        // continuous to true) and change the first step to display a tooltip in
        // the middle of the page.
        const newSteps = [
          {
            ...steps[0],
            ...STEP0_TOOLTIP,
          },
          ...steps.splice(1),
        ];

        updateWithDelay({
          steps: newSteps,
          continuous: true,
        });
      }
    } else if (type === EVENTS.STEP_AFTER) {
      let nextStepIndex = index;
      switch (action) {
        case ACTIONS.CLOSE: {
          // If a tooltip is closed we switch to beacon mode (by setting
          // continuous false) and change the first step to support beacons.
          const newSteps = [
            {
              ...steps[0],
              ...STEP0_BEACON,
            },
            ...steps.splice(1),
          ];

          updateWithDelay({
            steps: newSteps,
            continuous: false,
            run: true,
          });
          return;
        }
        case ACTIONS.NEXT:
          nextStepIndex += 1;
          break;
        case ACTIONS.PREV:
          nextStepIndex -= 1;
          break;
        default:
          break;
      }
      setState({
        ...state,
        stepIndex: nextStepIndex,
      });
    }
  };

  return (
    <Joyride
      callback={handleJoyrideCallback}
      continuous={continuous}
      run={run}
      disableScrolling={true}
      showProgress={false}
      showSkipButton={false}
      stepIndex={stepIndex}
      steps={steps}
      styles={{
        options: {
          primaryColor: '#4900FF',
        },
        overlay: { height: '100%' },
      }}
    />
  );
}
