import { useEffect } from 'react';
import {
  FINISH_TESTING,
  useAppState,
  useAppStateDispatch,
} from '../../contexts/AppStateProvider';

const Step4 = () => {
  const dispatch = useAppStateDispatch();
  const state = useAppState();
  const { sentinel } = state;

  useEffect(() => {
    sentinel
      .validateTransfer()
      .then((successfulTransfer) => {
        dispatch({
          type: FINISH_TESTING,
          data: { successfulTransfer },
        });
      });
  }, []);

  return (
    <>
      <div className="card w-2/4 bg-base-100 shadow-xl mb-16">
        <div className="card-body flex justify-center">
          <h2 className="text-center">Security Check</h2>
          <div className="card-actions justify-center mt-5">
            <span className="loading loading-spinner loading-lg">
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step4;
